import React, {useState} from 'react';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Toast from "../../utils/sharedComponents/Toast";
import MenuHomePage from "./MenuHomePage";
import {makeStyles} from "@material-ui/core/styles";
import HowItWorksCard from "./HowItWorksCard";
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import './HomePage.css'


const HomePage = () => {
    const images = [
        {
            original: 'https://firebasestorage.googleapis.com/v0/b/tcsrbija.appspot.com/o/bpdgolubac%2Fakcija.JPG?alt=media&token=1404c5d3-8b6e-49a9-96c1-dab74f478951',
        },
        {
            original: 'https://firebasestorage.googleapis.com/v0/b/tcsrbija.appspot.com/o/bpdgolubac%2Fbicikle.JPG?alt=media&token=07308d73-59b5-4888-9cfd-3ea860347bfb',
        },
        {
            original: 'https://firebasestorage.googleapis.com/v0/b/tcsrbija.appspot.com/o/bpdgolubac%2Fhome-page.JPG?alt=media&token=da927684-26b2-4309-b1e2-b3faadefe62e',
        },
        {
            original: 'https://firebasestorage.googleapis.com/v0/b/tcsrbija.appspot.com/o/bpdgolubac%2Fteam.JPG?alt=media&token=4cfd236d-4496-4554-adea-8ce3c352ff60',
        }, {
            original: 'https://firebasestorage.googleapis.com/v0/b/tcsrbija.appspot.com/o/bpdgolubac%2Fteam2.JPG?alt=media&token=ecd9ab88-349b-4279-ae37-7e6aca78341d',
        }, {
            original: 'https://firebasestorage.googleapis.com/v0/b/tcsrbija.appspot.com/o/bpdgolubac%2Fzastave.JPG?alt=media&token=4ff1cdd2-646e-420a-815c-5acc0f7e544a',
        },
        {
            original: 'https://firebasestorage.googleapis.com/v0/b/tcsrbija.appspot.com/o/bpdgolubac%2Fdance.JPG?alt=media&token=85427888-0899-4173-be99-d48cb22c3679',
        },
    ];

    const [toastStatus, setToastStatus] = useState(null);
    const classes = useStyles();

    function showToast(toastType, toastMessage) {
        setToastStatus({
            toastType: toastType,
            toastMessage: toastMessage,
            date: Date()
        })
    }

    return (
        <div>
            <MenuHomePage/>
            <Grid container direction={"row"} justify={'center'} alignItems={'center'} alignContent={"center"}
                  className={classes.getStartedContainer}>
                <Grid className={classes.gridItem} item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Typography className={classes.text} variant={"h1"}>Biciklističko Planinarsko Društvo
                        Golubac</Typography>
                    <Typography className={classes.text} variant={"h4"}>Spoj prirode i čoveka</Typography>
                </Grid>

                <Grid item xs={12} md={6}>
                    {/*<SignUpForm showToast={showToast}/>*/}
                    {/*<img src={'./home-page.jpg'} className={classes.img}/>*/}
                    {/*<Gallery/>*/}
                    <ImageGallery items={images} infinite={true} showThumbnails={false} showFullscreenButton={false}
                                  showPlayButton={false} autoPlay={true}
                                  showNav={false}
                                  slideDuration={600}
                    />
                </Grid>

            </Grid>

            <Grid container>
                <Grid item xs={12}>
                    <Grid container direction={"column"} alignContent={"center"} alignItems={"center"}>
                        <Grid item> <Typography variant={"h2"} className={classes.titleText}></Typography></Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                    <HowItWorksCard title={'Planinarske Staze'} imgSrc={'trails1.svg'}
                                    description={"Pogledajte planinraske staze i skinite mape u vaš telefon"}/>
                </Grid>
                <Grid item xs={12} md={6}>
                    <HowItWorksCard title={'Planinarski Dom'} imgSrc={'hotel.svg'}
                                    description={"Pogledajte plan izgradnje planinarskog doma "}/>
                </Grid>
            </Grid>

            <Grid container className={classes.howItWorksContainer}>
                <Grid item xs={12}>
                    <Grid container direction={"column"} alignContent={"center"} alignItems={"center"}
                          style={{marginTop: 30}}>

                        <Grid item>
                            <Typography variant={"h2"} className={classes.titleText}>Partneri</Typography></Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={4}>
                    <HowItWorksCard title={'Turistička Organizacija Golubac'} imgSrc={'golubac.jpg'}
                                    description={""}/>
                </Grid>
                <Grid item xs={12} md={4}>
                    <HowItWorksCard title={'Nacionalni Park Djerdap'} imgSrc={'djerdap.jpg'}
                                    description={""}/>
                </Grid>
                <Grid item xs={12} md={4}>
                    <HowItWorksCard title={'Tvrdjava Golubac'} imgSrc={'tvrdjava.png'}
                                    description={""}/>
                </Grid>
            </Grid>


            {/*<Grid container className={classes.howItWorksContainer}>*/}
            {/*    <Grid item xs={12}>*/}
            {/*        <Grid container direction={"column"} alignContent={"center"} alignItems={"center"}>*/}

            {/*            <Grid item> <Typography variant={"h2"} className={classes.titleText}>The 1099 recipient*/}
            {/*                Contractor</Typography></Grid>*/}
            {/*            <Grid item> <Typography variant={"h4"}>Your valued clients may love running their business. But*/}
            {/*                they dread the paperwork</Typography></Grid>*/}
            {/*        </Grid>*/}
            {/*    </Grid>*/}
            {/*    <Grid item xs={12} style={{marginTop: 30}}>*/}
            {/*        <Grid container direction={"column"} alignContent={"center"}>*/}
            {/*            <List component="nav" aria-label="main mailbox folders">*/}
            {/*                <ListItem>*/}
            {/*                    <ListItemIcon>*/}
            {/*                        <DoneOutlineIcon color={'primary'}/>*/}
            {/*                    </ListItemIcon>*/}
            {/*                    <ListItemText primary="list 1"/>*/}
            {/*                </ListItem>*/}
            {/*                <ListItem>*/}
            {/*                    <ListItemIcon>*/}
            {/*                        <DoneOutlineIcon color={'primary'}/>*/}
            {/*                    </ListItemIcon>*/}
            {/*                    <ListItemText*/}
            {/*                        primary="Tlist 2"/>*/}
            {/*                </ListItem>*/}
            {/*                <ListItem>*/}
            {/*                    <ListItemIcon>*/}
            {/*                        <DoneOutlineIcon color={'primary'}/>*/}
            {/*                    </ListItemIcon>*/}
            {/*                    <ListItemText*/}
            {/*                        primary="list 3"/>*/}
            {/*                </ListItem>*/}
            {/*            </List>*/}
            {/*        </Grid>*/}
            {/*    </Grid>*/}

            {/*</Grid>*/}

            <Grid container alignContent={"center"} justify={"center"} alignItems={"center"} className={classes.footer}>
                <Grid item xs={6}>
                    <Typography variant={"h5"}><br/><strong>Sedište</strong><br/></Typography>
                    <Typography>Cara Dušana 1<br/> Golubac <br/> </Typography>
                </Grid>

                <Grid item xs={6} style={{marginTop: 20}}>
                    {/*<SignUpForm showToast={showToast} color={'black'}/>*/}
                    {/*<Typography variant={"h4"}>Kontakt</Typography>*/}
                    {/*<Typography>telefon</Typography>*/}
                    {/*<Typography>email</Typography>*/}
                </Grid>
            </Grid>

            {toastStatus ? <Toast key={toastStatus.date} toastStatus={toastStatus}/> : null}

        </div>
    );
};

export default HomePage;

const useStyles = makeStyles((theme) => ({
    footer: {
        background: '#292D2C 0% 0% no-repeat padding-box',
        opacity: 1,
        color: 'white',
        textAlign: 'center'
    },
    img: {
        width: '100%',
        height: '100%'
    },
    gridItem: {
        // margin: theme.spacing(2)
    },
    getStartedContainer: {
        backgroundColor: theme.palette.primary.light,
        padding: theme.spacing(2),
        minHeight: '80vh',
        background:"url('./colored.svg')",
        backgroundSize: 'cover'
    },
    text: {
        color: theme.palette.neutral.main,
        margin: theme.spacing(3)
    },
    getStartedButton: {
        width: '60%',
        backgroundColor: theme.palette.primary.light,
        color: 'white',
        margin: 10,
        borderRadius: 30
    },

    getStartedBox: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: theme.palette.neutral.main,
        maxWidth: '80%',
        minHeight: '40vh',
        borderRadius: 10,
        padding: theme.spacing(3),
        marginBottom: 10,
        alignItems: 'center',
        alignContent: 'center',
        justifyContent: 'center',

    },
    titleText: {
        color: theme.palette.primary.light,
        margin: theme.spacing(4),
        marginBottom: 50,
        textAlign: 'center'
    },
    howItWorksContainer: {
        marginTop: theme.spacing(0),
        backgroundColor: 'white'
    },


}))

