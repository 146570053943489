import React from 'react';
import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";
import MenuHomePage from "../HomePage/MenuHomePage";
import {Typography} from "@material-ui/core";

const Actions = () => {
    const classes = useStyles();

    return (
        <div>
            <MenuHomePage/>
            <Grid container direction={"column"} alignContent={"center"} alignItems={"center"} justify={"center"}>
                <Grid item>
                    <Typography variant={"h3"}>Ova stranica je truntno u izradi</Typography>
                </Grid>
            </Grid>
        </div>
    );
};

export default Actions;

const useStyles = makeStyles((theme) => ({}));

