import React from 'react';
import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";
import MenuHomePage from "../HomePage/MenuHomePage";
import {Box, Typography} from "@material-ui/core";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import './Projects.css'

const Projects = () => {
    const classes = useStyles();

    const images = [
        {
            original: 'https://firebasestorage.googleapis.com/v0/b/tcsrbija.appspot.com/o/bpdgolubac%2FgrantCivilNature%2FJelenska%20stena.jpg?alt=media&token=dbdcf4d5-7956-49a1-8957-a3f8497ab1bc',
            originalHeight: '100px'

        },
        {
            original: 'https://firebasestorage.googleapis.com/v0/b/tcsrbija.appspot.com/o/bpdgolubac%2FgrantCivilNature%2FBeg%20bunar-%20Bojana.jpg?alt=media&token=8827cc95-703c-4d8b-a44c-831df053a7c0',
            originalHeight: '100px'

        },
        {
            original: 'https://firebasestorage.googleapis.com/v0/b/tcsrbija.appspot.com/o/bpdgolubac%2FgrantCivilNature%2FKanjon%20Brnji%C4%8Dke%20reke.jpg?alt=media&token=a84e7119-bd90-44bd-98dc-04b492080ae6',
            originalHeight: '100px'

        },
        {
            original: 'https://firebasestorage.googleapis.com/v0/b/tcsrbija.appspot.com/o/bpdgolubac%2FgrantCivilNature%2FTvr%C4%91ava-Brnjica.jpg?alt=media&token=1a479b3d-563a-4681-9d58-252a70d40966',
            originalHeight: '100px'

        }, {
            original: 'https://firebasestorage.googleapis.com/v0/b/tcsrbija.appspot.com/o/bpdgolubac%2FgrantCivilNature%2Fkru%C5%BEna%20staza.jpg?alt=media&token=0de7d191-bc70-40ba-a641-ff91f081d4f2',
            originalHeight: '100px'

        },
    ];


    return (
        <div>
            <MenuHomePage/>
            <Grid container direction={"column"} alignContent={"center"} alignItems={"center"} justify={"center"}
                  style={{marginTop: 20}}>
                <Box maxWidth={1200} padding={5}>
                    <Grid item container spacing={3}>
                        <Grid item style={{textAlign: 'center'}}>
                            <Typography variant={"h3"}><strong>Support Program for Civil Society Organizations for the
                                Protection of
                                Nature and Environment</strong></Typography>
                            <Typography variant={"h4"}>(Program za pomoc gradjanskim udruženjima za zaštitu
                                prirode)</Typography>
                        </Grid>

                        <Grid item style={{
                            display: 'flex',
                            alignContent: "center",
                            justifyContent: 'center',
                            alignItems: 'center',

                        }} xs={12} sm={6}>
                            <ImageGallery items={images} infinite={true} showThumbnails={false}
                                          showFullscreenButton={true}
                                          showPlayButton={true} autoPlay={true}
                                          showNav={true} originalHeight={'100px'}
                                          slideDuration={600}
                            />
                        </Grid>

                        <Grid item style={{textAlign: 'center', marginTop: 10}} xs={12} sm={6}>
                            <Typography variant={"body1"}>U projektu Support Program for Civil Society Organizations for the
                                Protection
                                of Nature and Environment, i
                                grantu od Svedske organizacije WWF Adria, BPD Golubac je uradio idenji projekat novih
                                planinarskih
                                staza u NP Djerdap, na podrucju opstine Golubac, izvrsio GPS snimanje na terenu i
                                obradio
                                podatke
                                tako de se mogu koristiti u navigacionim uredjajima.NP Djerdap ce ,u skladu sa svojim
                                mogucnostima i
                                zakonskom regulativom da odluci kada ce pomenute staze biti odobrene i dostupne za
                                planinarske
                                akcije.</Typography>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
        </div>
    );
};

export default Projects;

const useStyles = makeStyles((theme) => ({
    ".image-gallery": {
        "width": "40%",
        "height": "auto"
    },
    ".image-gallery-slide img": {
        "width": "100%",
        "height": "auto",
        "maxHeight": "10vh",
        "objectFit": "cover",
        "overflow": "hidden",
        "objectPosition": "center center"
    },
    ".fullscreen .image-gallery-slide img": {
        "maxHeight": "100vh"
    }
}));

